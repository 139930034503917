// 複数チェックボックス有効
const labels = document.querySelectorAll(".c-searchCourse-category-item__body-item input");


labels.forEach((label) => {
  label.addEventListener("click", (e) => {
    const targetId = label.getAttribute("id");
    if(!label.classList.contains("checked")) {
      $("input#" + targetId).addClass("checked");
      $("input#" + targetId).prop("checked", true);
    } else {
      $("input#" + targetId).removeClass("checked");
      $("input#" + targetId).prop("checked", false);
    }
  });
});


//全選択/全解除機能
const target = document.querySelector(".js-all-check");

const targets = document.querySelectorAll(".js-all-check");

targets.forEach((label) => {
  label.addEventListener("click", () => {
    if (!target.closest(".-is-area").classList.contains("-is-all-checked")) {
      target.closest(".-is-area").classList.add("-is-all-checked");
      $(".-is-area").find("input").prop("checked", true);
    } else {
      target.closest(".-is-area").classList.remove("-is-all-checked");
      $(".-is-area").find("input").prop("checked", false);
    }
  });
});

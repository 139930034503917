
// $('.l-lists-option__display-item').click(function () {
//   $(this).siblings().removeClass('is-current');
//   $(this).toggleClass('is-current');
// });


var store = require("store");
import addEventListenerMultiType from "../common/multiEventLister.js";

if ($(".l-lists-option__display-item").length) {
  class SwitchLayout {
    constructor() {
      this.html = document.querySelector("html");

      this.swicher = document.querySelector(".l-lists-option__display");

      this.targets = document.querySelectorAll(".l-lists-option__display-item");

      this.panel = document.querySelector(".l-lists__ul");
    }

    init() {
      this.attachEvent();
      this.switchLayout();
    }

    switchLayout() {
      if (this.getLayoutStorage() == "card") {
        //ボタンカレント
        this.swicher.setAttribute(
          "class",
          this.swicher
            .getAttribute("class")
            .replace("-switch-article", "-switch-card")
        );

        //リストレイアウト
        this.panel.setAttribute(
          "class",
          this.panel
            .getAttribute("class")
            .replace("-layout-article", "-layout-card")
        );
      }

      if (this.getLayoutStorage() == "article") {
        //ボタンカレント
        this.swicher.setAttribute(
          "class",
          this.swicher
            .getAttribute("class")
            .replace("-switch-card", "-switch-article")
        );

        //リストレイアウト
        this.panel.setAttribute(
          "class",
          this.panel
            .getAttribute("class")
            .replace("-layout-card", "-layout-article")
        );
      }
    }

    setLayoutStorage(target) {
      store.set("switchlayout", target.dataset.switchlayout);
    }

    getLayoutStorage() {
      return store.get('switchlayout');
    }

    attachEvent() {
      for (let target of this.targets) {
        addEventListenerMultiType(target, "click", (e) => {
          this.setLayoutStorage(target);
          this.switchLayout();
        });
      }
    }
  }

  const switchLayout = new SwitchLayout();
  switchLayout.init();
}


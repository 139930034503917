import swiper from "swiper";

if ($(".top-hero").length) {
  var serviseNav = new swiper(".top-hero-slider__detail", {
    direction: "horizontal",
    loop: true,
    speed: 500,
    autoplay: false,
    spaceBetween: 8,
    centeredSlides: true,
    observer: true,
    allowTouchMove: true,
    preloadImages: false,
    autoHeight: true,
    slidesPerView: 1.5,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      // 960: {
      //   slidesPerView: 2
      // },
      768: {
        spaceBetween: 5,
        slidesPerView: 1.2
      }
    },
    navigation: {
      nextEl: '.top-hero-slider-next',
      prevEl: '.top-hero-slider-prev',
    }
  });
}

if ($(".course-detail").length) {
  //サムネイル
  var sliderThumbnail = new swiper('.thumbnail', {
    slidesPerView: 4,
    freeMode: false,
    observer: true,
    allowTouchMove: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    spaceBetween: 8,
    breakpoints: {
      768: {
        // spaceBetween: 10,
      }
    },
  });

  //スライダー
  var slider = new swiper('.slider', {
    slidesPerView: "auto",
    freeMode: false,
    observer: false,
    allowTouchMove: false,
    effect: "fade",
    // autoHeight: true,
    thumbs: {
      swiper: sliderThumbnail
    }
  });
}